angular
  .module('zestimate', [])
  .service('ZillowAPI', ['$http', '$sce', '$q', function ($http, $sce, $q) {
    var BASE_URL = '',
      SEARCH_RESULTS = '/wp-json/zillow/v1/search?',
      CHARTS_RESULTS = '/wp-json/zillow/v1/charts?',
      COMPARISON_RESULTS = '/wp-json/zillow/v1/compare?';

    function fetchCharts(zpid) {
      var defer = $q.defer(),
        chartsUrl = $sce.trustAsResourceUrl(BASE_URL + CHARTS_RESULTS + '&zpid=' + zpid);

      $http.get(chartsUrl).then(function (response) {
        defer.resolve(response.data.response);
      }, function (err) {
        defer.reject(err.message);
      });

      return defer.promise;
    }

    function fetchComparison(zpid) {
      var defer = $q.defer(),
        comparisonUrl = $sce.trustAsResourceUrl(BASE_URL + COMPARISON_RESULTS + '&zpid=' + zpid);

      $http.get(comparisonUrl).then(function (response) {
        defer.resolve(response.data.response);
      }, function (err) {
        defer.reject(err.message);
      });

      return defer.promise;
    }

    function getDetails(zpid) {
      return $q.all(
        {
          charts: fetchCharts(zpid),
          comparison: fetchComparison(zpid)
        }
      );
    }

    function fetchProperty(address) {

      var defer = $q.defer(),
        searchUrl = $sce.trustAsResourceUrl(BASE_URL + SEARCH_RESULTS + '&address=' + address.street + '&citystatezip=' + address.citystate);

      $http.get(searchUrl).then(function (response) {
        defer.resolve(response.data.response.results.result);
      }, function (err) {
        defer.reject(err.message);
      });

      return defer.promise;
    }

    function setBaseUrl(url) {
      BASE_URL = url;
    }

    function urlEncode(str) {
      return str ? window.encodeURIComponent(str) : '';
    }

    return {
      fetchProperty: fetchProperty,
      getDetails: getDetails,
      setBaseUrl: setBaseUrl,
      urlEncode: urlEncode
    };
  }])
  .controller('zestController', ['$scope', 'ZillowAPI', function ($scope, api) {

    function handleError(err) {
      $scope.error = {
        message: err
      };
    }

    function init() {
      var baseUrl = document.querySelector('[ng-app="zestimate"]').attributes['data-home'];

      $scope.state = 'CA';

      if (baseUrl) {
        api.setBaseUrl(baseUrl.nodeValue);
      }
    }

    $scope.onAddressSubmit = function () {
      $scope.error = null;

      if ($scope.address && $scope.state && $scope.zip && $scope.city) {
        var street = $scope.address,
          citystate = $scope.city + ', ' + $scope.state + ' ' + $scope.zip;

        api
          .fetchProperty({
            street: api.urlEncode(street),
            citystate: api.urlEncode(citystate)
          })
          .then(function (response) {
            return response.zpid;
          }, handleError)
          .then(function (response) {
            return api.getDetails(response);
          }, handleError)
          .then(function (response) {
            $scope.propertyDetails = response;
          }, handleError);
      } else {
        $scope.error = {
          message: 'Please enter a full address'
        };
      }
    };

    init();
  }]);
